import React from 'react'
import PackeryModule from 'react-packery-component'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import Title from 'components/title'
import DownloadableFiles from 'components/downloadableFiles'
import { trFunction } from 'utils/functions'

const Packery = PackeryModule(React)
const packeryOptions = {
  itemSelector: '.item-grid',
  percentPosition: true,
  gutter: '.gutter-sizer',
  transitionDuration: '0', //'0.4s'
}

const Material = ({ material }) => {
  return (
    <div className="item-grid">
      <div className="img-wrapper-container">
        <div className="img-wrapper-proportion">
          <ImageWrap image={material.image} />
        </div>
      </div>
      <div
        className="caption"
        dangerouslySetInnerHTML={{ __html: material.title }}
      />
    </div>
  )
}

const MaterialsPageTemplate = ({ data, section }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage
  let downloadableFiles = []
  if (data.downloadableFile1) downloadableFiles.push(data.downloadableFile1)
  if (data.downloadableFile2) downloadableFiles.push(data.downloadableFile2)
  const materialBases = data.allMaterialBases.edges
  const sectionCapitalized = section[0].toUpperCase() + section.substr(1)
  const showInSection = `showIn${sectionCapitalized}`
  return (
    <Layout>
      <div id="content" className="page-materials">
        <Title
          title={page.title}
          subtitle={sectionCapitalized}
          subtitleLink={`/${section}`}
        />
        <section className="page-content wrap">
          {page.body && (
            <div
              className="wrap-text-large centered strip-p"
              dangerouslySetInnerHTML={{
                __html: page.body.childMarkdownRemark.html,
              }}
            />
          )}
        </section>
        {downloadableFiles.length > 0 && (
          <DownloadableFiles items={downloadableFiles} />
        )}
        <section className="page-content wrap">
          <div className="materials-wrapper">
            {materialBases.map((materialBase, i) => {
              if (materialBase.node[showInSection])
                return (
                  <div key={i}>
                    <h2>{materialBase.node.title}</h2>
                    {materialBase.node.materialCats.map((materialCat, i) => {
                      if (materialCat[showInSection])
                        return (
                          <div key={i}>
                            {!materialCat.hideTitle && (
                              <h3>{materialCat.title}</h3>
                            )}
                            {materialCat.materialSubcats.map(
                              (materialSubcat, i) => {
                                if (materialSubcat[showInSection])
                                  return (
                                    <div key={i}>
                                      {!materialSubcat.hideTitle && (
                                        <h3 className="sub-cat">
                                          {materialSubcat.title}
                                          <span>{materialSubcat.subtitle}</span>
                                        </h3>
                                      )}
                                      <Packery
                                        className={
                                          'grid-packery grid-materials'
                                        }
                                        options={packeryOptions}
                                      >
                                        <div className="gutter-sizer" />
                                        {materialSubcat.materials.map(
                                          (material, i) => {
                                            if (material[showInSection])
                                              return (
                                                <Material
                                                  key={i}
                                                  material={material}
                                                />
                                              )
                                          },
                                        )}
                                      </Packery>
                                    </div>
                                  )
                              },
                            )}{' '}
                            {/* end materialSubcats */}
                          </div>
                        )
                    })}{' '}
                    {/* end materialCats */}
                  </div>
                )
            })}{' '}
            {/* end materialBase */}
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default MaterialsPageTemplate
