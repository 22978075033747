import React from 'react'
import MaterialsPageTemplate from 'components/pageTemplates/materials'
import { graphql } from 'gatsby'

const Materials = ({ data }) => (
  <MaterialsPageTemplate data={data} section="bespoke" />
)

export default Materials

export const materialsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "materials" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    downloadableFile1: contentfulDownloadableFiles(
      slug: { eq: "materials-bespoke" }
    ) {
      id
      title
      file {
        file {
          url
        }
      }
      externalLink
    }
    downloadableFile2: contentfulDownloadableFiles(
      slug: { eq: "materials-bespoke-2" }
    ) {
      id
      title
      file {
        file {
          url
        }
      }
      externalLink
    }
    allMaterialBases: allContentfulMaterialBase(sort: { fields: [order] }) {
      edges {
        node {
          id
          title
          order
          showInCollections
          showInBespoke
          materialCats {
            id
            title
            hideTitle
            showInCollections
            showInBespoke
            materialSubcats {
              id
              title
              hideTitle
              subtitle
              showInCollections
              showInBespoke
              materials {
                title
                image {
                  ...GatsbyImage
                }
                showInCollections
                showInBespoke
              }
            }
          }
        }
      }
    }
  }
`
